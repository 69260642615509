import { useRef, useState } from "react";
import  FAQJin from "../assets/images/faq-jin.png"
import Accordion from "../components/accordion/accordion"
import {  NavLink }  from  "react-router-dom"
import scrolling from "../hooks/scrolling";
import Swap from "../components/swap/swap";



const FAQtPage =()=>{
  const [isScrolling, setIsScrolling] = useState(false);
  const elementRef = useRef(null)
  scrolling(setIsScrolling, elementRef )



  return(
    <>
      <div className="faqContainer">
      <div className="content pt">
        <div className="faq-content">
          <div className="faq-content-image">
            <img src={FAQJin} alt="faq Jin"/>
          </div>
          faq-descriptions
          <div className={`faq-descriptions ${isScrolling ? "scrolling": "no-scrolling"}`} ref={elementRef}> 
              <h1>Frequently Asked Questions</h1>
              <Accordion/>

              <div className="accordion-contact-section">
                <h3>Can’t find it here?</h3>
                <NavLink to="/contactUs"><span>Contact us</span></NavLink>
              </div>

         </div>
     </div>
   </div>
  </div>
  <Swap/>
    </>
  
  
    
     
  
           
       
  
   
    )
}
export  default FAQtPage
