import { useState } from "react";
import ContactUsInput from "./contactUsInput"
import axios from "axios";
import ContactUsTextare from "./contactUstextarea";


const ContactUsForm = ()=>{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [error,setError] = useState({
         name: '',
         email: '',
         comment: '',
    })


    // validate form
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
      };

    const handleSubmit =  (e) => {
        e.preventDefault();
        const errors = {};

    if (!name) {
      errors.name = "Name is required.";
    } else if(name.length < 2){
        errors.name = "Invalid name format.";
    }

    if (!email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(email)) {
      errors.email = "Invalid email format.";
    }

    if (!comment) {
      errors.comment = "Comment is required.";
    }

    setError(errors);
   
    if (Object.keys(errors).length === 0) {
       // TO DO

        // const formData = { name, email, comment };
        // const apiUrl = ' ';
        // axios.post(apiUrl, formData)
        //     .then((response) => {
        //         console.log('Response:', response.data);
        //     })
        //     .catch((error) => {
        //         console.error('Error:', error);
        //     });
        //
    }   
    };
  
    return (
        <div className="contact-us-form">
            <h1>Contact Us</h1>
            <form className="form">
                <ContactUsInput label = "Name" type = "text" value = {name} setValue = {setName} error={error.name} setError={setError} />
                <ContactUsInput label = "Email" type = "text" value = {email} setValue= {setEmail} error={error.email} setError={setError} />
                <ContactUsTextare comment={comment}  setComment={setComment} error={error.comment} />
                <div><button className="contactUs-button" data="Submit" onClick={handleSubmit} ></button> </div>
            </form>
        </div>
    )
}

 export default ContactUsForm