 import { useRef, useState} from "react"

 import SpinFormBtn  from "../../assets/images/spin-input-btn.png"
 import useOnClickOutSide from "../../hooks/useOnClickOutSide"
import SwapArrow from "../../assets/images/swap-arrow.png"
import { swapOption } from "../../mockData/swapOption"
import CloseSwap  from "../../assets/icons/minus.svg"
import SelectItems from "./selectItems"
import SwapInput from "./swapinput"
import SwapLable from "./swaplable"
import axios from "axios";





const Swap = ()=>{

    const elementRef = useRef(null)
    useOnClickOutSide(elementRef,()=>{setSwapOpen(false)})
    const [swapOpen , setSwapOpen] =  useState(false)
    const [valueFrom, setValueFrom] = useState("");
    const [valueTo, setValueTo] = useState("");
    const [selectActionFrom, setSelectActionFrom] = useState(swapOption[0])
    const [selectActionTo, setSelectActionTo] = useState(swapOption[1])
   

    // send data
    const sendSwap=(e)=>{
        e.preventDefault()

        const dataToSend = {
            valueFrom,
            selectedOptionFrom:selectActionFrom.title,
            inputValueTo:valueTo,
            selectOptionTo:selectActionTo.title
        };


        // TO DO
        // const apiUrl = ' ';
        // axios.post(apiUrl, dataToSend)
        //     .then((response) => {
        //         console.log('Response:', response.data);
        //     })
        //     .catch((error) => {
        //         console.error('Error:', error);
        //     });
        //

        
        console.log(dataToSend)

    }


    // rivers swap value
    const swapRiversBtn = ()=>{
        // setValueFrom(valueTo)
        // setValueTo(valueFrom)
        // setSelectActionFrom(selectActionTo)
        // setSelectActionTo(selectActionFrom)

        setValueFrom((prev)=>{
            setValueTo(prev)
            return valueTo
        })

        setSelectActionFrom((prev)=>{
            setSelectActionTo(prev)
            return {...selectActionTo}
        })
    }
    return (
        <>
         <div className={`swap-bg ${swapOpen ? "btn-none" : "btn-block"}`} onClick={(e)=>{e.preventDefault(); setSwapOpen(true)}} >
            <div className="swap-img">
            <img src={SwapArrow} alt="Swap Arrow"/>
            </div>
            <span className="swap-title">Swap</span>
           </div>
           {
            swapOpen &&
            <div className="swap-content" ref = {elementRef}>

                <div className="swap-content-border">
                <div className="swap-content-title">
                    <h3>Swapping</h3>
                    <div className="swap-close" onClick={()=>setSwapOpen(false)}><img src={CloseSwap} alt="Close Swap"/></div>
                </div>
                </div>
                <form className="swap-form">
                    <div className="swap-container">
                        <div>
                          <SwapLable lable="From" />
                            <div className="form-box">
                                <SwapInput value= {valueFrom} setValue={setValueFrom}/>
                                <SelectItems selectAction={selectActionFrom} setSelectAction ={setSelectActionFrom} />
                             </div>
                        </div>
                        <div>
                          <SwapLable lable="To" />
                            <div className="form-box">
                                <SwapInput value={valueTo} setValue={setValueTo} />
                                <SelectItems selectAction={selectActionTo} setSelectAction ={setSelectActionTo} />
                             </div>
                        </div>
                    </div>
                     <div className="swap-container-btn">
                        <button className="cencel-button" data="Cancel" onClick={() => setSwapOpen(false)}></button>
                        <button className="swap-button" data="Swap" onClick={sendSwap} ></button>
                    </div>
                    <div className="spin-inp-btn" onClick={swapRiversBtn} >
                        <img src={SpinFormBtn} alt="Spinner button" />
                    </div>
                </form>
    
            </div>
           }
        </>

    )

}
 export default Swap
