export const dataSlot = {
    "lines": [
        {
            "symbol": "W",
            "count": 3,
            "cf": 12,
            "sv": 8,
            "line": [
                "X6",
                "X7",
                "X8",
                "X9",
                "X10"
            ]
        }
    ],
    "winList": {
        "X1": 7,
        "X2": 7,
        "X3": 9,
        "X4": 9,
        "X5": 6,
        "X6": 8,
        "X7": 8,
        "X8": 8,
        "X9": 5,
        "X10": 1,
        "X11": 9,
        "X12": 6,
        "X13": 9,
        "X14": 9,
        "X15": 7
    }
}












