import { useEffect, useState } from "react";

import popupImg from ".././../assets/images/popup-img.png"


  const DialogPopup =({combinedClickHandler})=>{
    const [isHovered, setIsHovered] = useState(false);


  // count-- works after update page
  useEffect(()=>{
    const storedListCount = localStorage.getItem('listCount');
    
    if(storedListCount>0){
      const dialog = document.getElementById('modal');
  
      dialog.showModal();
      const closeDialogButton = document.getElementById('closeModal');
      closeDialogButton.addEventListener('click', () => {
        dialog.close();
        combinedClickHandler();
      });
    }
  },[])


 // button hover 
  const handleHover = () => {
    setIsHovered(true);
  };
  const handleUnhover = () => {
    setIsHovered(false);
  };






    return (
        <dialog id="modal" className="modal">

            <div className="modal-content">
            <p className="modal-massage">Your spinning will be continue?</p>
            <div className="popup-btn">
             <div className={`popup-img ${isHovered ? 'hovered' : 'no-hovered'}`}>
             <img src={popupImg} alt="Bat" /></div>

            <button 
              id="closeModal"
              className="modal-close-btn"
               onMouseEnter={handleHover}
              onMouseLeave={handleUnhover}>Ok</button>
       
           
        </div>
            </div>


      


     
      </dialog>
    )
  }
   export default DialogPopup