import { NavLink } from "react-router-dom"
import {useEffect} from "react";

import HomepageButton from "../homePage/homePageButton"
import {useSpin} from "../../context/SpinContext";
import Jin from "../../assets/images/Jin.png"



const Banner =()=>{
    const { setPlay, setDemo} = useSpin()

    //play button click
    const handleDemoButtonClick = () => {
        setDemo(true);
        setPlay(false);
        localStorage.setItem('demo', 'true');
        localStorage.removeItem('play');
    };
    // demo button click
    const handlePlayButtonClick = () => {
        setPlay(true);
        setDemo(false);

        localStorage.setItem('play', 'true');
        localStorage.removeItem('demo');
    };

    useEffect(() => {
        const demoValue = localStorage.getItem('demo');
        const playValue = localStorage.getItem('play');

        if (demoValue === 'true') {
            setDemo(true);
            setPlay(false);
        } else if (playValue === 'true') {
            setPlay(true);
            setDemo(false);
        }
    }, []);

    return(
      <div className="banner">
         <div className="content pt">
          <div className="banner-info">
          <div className="banner-content">
            <div className="banner-title">
              <h1>Get up to <span>$50 000</span> and be a king</h1>
              <div className="homePage-button">
                  <NavLink to= "/demo"><HomepageButton name="Demo" className =" demo-button "   onClick={handleDemoButtonClick}  /></NavLink>
                  <NavLink to= "/play"> <HomepageButton name="Play" className = "play-button" onClick={handlePlayButtonClick}/></NavLink>
              </div>
            </div>
          </div>
          <div className="jin-bg">
          <img src={Jin} alt="Jin" />
          </div>
          </div>
          </div>
      </div>
  )
}
export default Banner
