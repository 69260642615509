import { useState, useRef, useEffect } from "react";
import { swapOption } from "../../mockData/swapOption";
import SelectArrow from "../../assets/icons/select-arrow.svg";
import useOnClickOutside from "../../hooks/useOnClickOutSide";

const SelectItems = ({selectAction,setSelectAction}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, ()=>{setIsDropdownOpen(false)})
  const optionTitles = swapOption.map(option => option.title);

  const toggleDropdown = () => {
     setIsDropdownOpen(!isDropdownOpen);
   };


  const handleSelect = (option) => {
    setSelectAction(option)
     swapOption.map((opt) => opt.disabled = false);
     option.disabled = !!optionTitles.includes(option.title);
      toggleDropdown();
  };


  return (
      <div
          className={`swap-select ${isDropdownOpen ? "swap-select-radius" : ""}`}
          ref={dropdownRef}
      >
        <div className="select-header" 
         onClick={toggleDropdown}
        >
          <div className="selected-option">
            <div className="selected-option-img">
              <img src={selectAction.src} alt={selectAction.title} />
            </div>
            <span className="selected-option-title">{selectAction.title}</span>
          </div>
          <div
              className={`dropdown-icon ${
                  isDropdownOpen ? "dropdown-icon-open" : ""
              }`}
          >
            <img src={SelectArrow} alt="Select Arrow" />
          </div>
        </div>

        {isDropdownOpen && (
            <ul className= "options-list">
              {swapOption.map((option) => {
                 const isDisabled = option.title === selectAction.title || option.disabled;
                return (
                    <li
                        key={option.title}
                        onClick={() => {
                           if (!isDisabled) {
                             handleSelect(option);
                           }
                        }}
                         className={isDisabled   ? "disabled-option" : "no-disabled-option"}
                    >
                      <div className="selected-option-img">
                        <img src={option.src} alt={option.title} />
                      </div>
                      <span className="selected-option-title">{option.title}</span>
                    </li>
                );
              })}
            </ul>
        )}

      </div>
  );
};

export default SelectItems;
