import { useRef, useState } from "react";
import Whitepaperjin  from "../assets/images/whitepaper-Jin.png"
import { whitePaperdata } from "../mockData/whitepaper"
import scrolling from "../hooks/scrolling";
import Swap from "../components/swap/swap";




const WhitePaper =()=>{
  const [isScrolling, setIsScrolling] = useState(false);
  const elementRef = useRef(null)
  scrolling(setIsScrolling, elementRef )



  return(
    <>
    <div className="whitePaperContainer">
    <div className="content pt">
      <div className="whitePaper-content">

        <div className="whitePaper-content-image">
          <img src={Whitepaperjin} alt="Whitepaper Jin"/>
        </div>
      
      <div className={`whitePaper-descriptions ${isScrolling ? "scrolling": "no-scrolling"}`} ref={elementRef}> 
            <h1>Whitepaper</h1>
           <div className="whitePaper-desc">
              {
                 whitePaperdata.map((data,ind)=>{
                    return(
                        <div key={ind}>
                           <h2>{data.title}</h2>
                           <div className="whitePaper-descs">{data.desc}</div>
                        </div>
                      )
                  })
              }
        </div>
       </div>
   </div>
 </div>
     </div>
    <Swap/>
</>



  )
}
 export  default WhitePaper
