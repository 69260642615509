import React, { createContext, useContext, useState } from "react";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');

  return (
    <WalletContext.Provider
      value={{ connected, setConnected, wallet, setWallet, walletAddress, setWalletAddress }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  return useContext(WalletContext);
};
