import React, { useEffect, useRef,useState } from "react";
import SlotItem from "./slotItem";
import { useSpin } from "../../context/SpinContext";


const VerticalLine = ({
  reel,
  winItems,
  verticalLineIndex,
  setReels,
  result,
}) => {
  const {isSpinning,setIsSpinning,stopSpin} = useSpin()
  const spinningDurations = [500, 1000, 1500, 2000, 3000];
  const spinningInterval = 100;
  const stopSpinRef = useRef(stopSpin);


  // spinner
  async function spinner(reelIndex, spinningDuration) {
    const steps = spinningDuration / spinningInterval;
    for (let step = 0; step < steps; step++) {
      if (stopSpinRef.current) {
        break; 
      }
      await new Promise(resolve => setTimeout(resolve, spinningInterval));
      setReels(prevReels =>
        prevReels.map((reel, index) =>
           index === reelIndex  
            ? [reel[reel.length - 1], ...reel.slice(0, -1)]
            : reel
        ) 
      );
    }
    if (!stopSpinRef.current) {
      setReels(prevReels =>
        prevReels.map((reel, index) =>
          index === reelIndex ? result[reelIndex] : reel
        )
      );
    }
  }
  useEffect(() => {
    stopSpinRef.current = stopSpin; 
    if (isSpinning) {
      const promises = spinningDurations.map((duration, reelIndex) => {
        return spinner(reelIndex, duration);
      });

      Promise.all(promises)
        .then(() => {
          setReels(result);
        
        })
        .catch(error => {
          console.error("An error occurred:", error);
        })
        .finally(() => {
          setIsSpinning(false); 
        });
  
    } else if (stopSpinRef.current) {
      setReels(result);
    }

  }, [isSpinning, stopSpin]);
  
// console.log(reel)

  return (
    <>
      {reel  && reel.map((symbol, symbolIndex) => (
        <SlotItem
          symbol={symbol}
          key={symbolIndex}
          win={winItems.includes(symbolIndex * 5 + verticalLineIndex)}
        />
      ))}
    </>
  );
};

export default VerticalLine;
