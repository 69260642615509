import React, { useState } from "react";

import { questionsAnswers } from "../../mockData/faq";
import AccordionItem from "./accordionitem";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null); 

  const toggleAccordionItem = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="faq">
      {questionsAnswers.map((item, index) => {
        const isOpen = index === activeIndex;
        return (
          <AccordionItem
            key={index} 
            showDescription={isOpen ? "show-description" : ""}
            ariaExpanded={isOpen ? "true" : "false"}
            item={item}
            index={index}
            onClick={() => {
              toggleAccordionItem(index);
            }}
          />
        );
      })}
    </div>
  );
};

export default Accordion;
