import { createBrowserRouter } from "react-router-dom";
import Header from "../components/main/header";

import WhitePaper from "./whitePaper"
import Contact from "./contactUs"
import Faq  from "./faq"
import Demo from "./demo"
import Play from "./play"


import Homepage from "../pages/homepage";



 export default createBrowserRouter([
    {
        path:"/",
        element:<>
          <Header/>
          <Homepage/>
        </>
    },
    ...WhitePaper,
    ...Contact,
    ...Faq ,
    ...Demo,
     ...Play

 ])
