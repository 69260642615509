import React from "react";
import Header from "../components/main/header";
import ContactUsPage from "../pages/contactUs";





export default [

    {
        path: "/contactUs",
        element:<><Header/><ContactUsPage/></>
    }
]
