
const ContactUsInput = ({label,type,value,setValue,error})=>{

const handleChange = (e)=>{
    setValue(e.target.value)

}
    return(
        <>
        <div className="form-item">
        <input type={type} onChange={handleChange}  required  value={value} id = {type}  name={type}  />
        <label htmlFor={type} >{label}</label>
        {error && <small className="validate-error">{error}</small>}
        </div>
       
        </>
    )
}
 export default ContactUsInput