import {Connection, PublicKey} from '@solana/web3.js';
import * as buffer from "buffer";


window.Buffer = buffer.Buffer;

export const establishConnection = async (setWallet,setWalletAddress,setConnected ) => {

    const rpcUrl = 'https://long-morning-waterfall.solana-mainnet.discover.quiknode.pro/518985538e0ba735bd0b04a49dc59beb9bca4177/';
    const connection = new Connection(rpcUrl, 'confirmed');

            
    try {
        const walletAvailable = window.solana && window.solana.isPhantom;
        if (walletAvailable) {
            const connectedWallet = await window.solana.connect();
            console.log(connectedWallet )
            setWallet(connectedWallet);

            const walletAddress = connectedWallet.publicKey.toString();

            console.log( walletAddress + '  55555')
            setWalletAddress(walletAddress);

            const publicKey = new PublicKey(walletAddress);
            setConnected(true);


       

        }
    } catch (error) {
        console.error('Error connecting to Phantom Wallet:', error);
    }
};

