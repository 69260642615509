import "./App.css"
import "./index.css"
import "../src/assets/css/style.css"
import "../src/assets/css/slot.css"
import "../src/assets/css/swap.css"
import "../src/assets/css/accordion.css"
import "../src/assets/css/popup.css"

import "../src/assets/css/media.css"


function App() {
  return (
    <>
    </>
  );
}
export default App;
