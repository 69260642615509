import React from "react";

import SlotMachine from "../components/slot/slotMachine";
import Header from "../components/main/header";





export default [

    {
        path: "/demo",
        element:<><Header/><SlotMachine/></>
    }
]
