import ContactUsJin from "../../src/assets/images/contact-Us-Jin.png"
import ContactUsForm from "../components/contactUsForm/contactUsForm"
import Swap from "../components/swap/swap"

const ContactUsPage =()=>{
  return(
    <>
        <div className="contactUsContainer">
      <div className="content pt">
        <div className="contact-us-page">
          <ContactUsForm/>
          <div className="contact-us-page-bg">
          <div className="contact-us-page-image">
            <img src={ContactUsJin} alt="Jin"/>
          </div>
          </div>
        </div>
   </div>
  </div>
  <Swap/>
    </>

  
  
    )
}
export  default ContactUsPage
