import React, { useState, useEffect, memo } from 'react';

import transformDataForDisplay from "../../hooks/transferData";
import { useSpin} from "../../context/SpinContext";
import ButtonCollection from './buttonCollection';
import { symbols } from '../../mockData/symbols';
import VerticalLine from './verticalLine';
import SpinningSound from './spiningSound';
import WinPrice from './winPrice';
import slotDataApi from '../../API/slotDataApi';

const getMatrix = (array) => {
   const matrix = [];
   for (let i = 0; i < 5; i++) {
      matrix[i] = [];
      for (let j = 0; j < 9; j++) {
         matrix[i][j] = array[Math.floor(Math.random() * array.length)];
      }
   }
   return matrix;
};

const SlotMachine = () => {
   const {listCount,showWinPrice,slotData,setSlotData, setShowWinPrice,win,setStopSpin,isSpinning,setIsSpinning ,setListCount, startStopSpin, setStartStopSpin, fastStartSpin, setFastStartSpin, totalBet, winResult, setWinResult} = useSpin();
   const [reels, setReels] = useState(getMatrix(symbols));
 
   const [winCombination, setWinCombination] = useState([]);
   const [outputArray, setOutputArray] = useState([]);

   const fetchData = async () => {
      try {
        const data = await slotDataApi();
        setSlotData(data);
        setStartStopSpin(true);
        setFastStartSpin(true);
        console.log('Data from the API:', data);
      } catch (error) {
        return fetchData();
      }
    }
    

   // transfer data
    useEffect(() => {
         const transformedData = transformDataForDisplay(slotData.winList, symbols).outputArray;
         setOutputArray(transformedData);

    }, [slotData, symbols]);
   

   // slot win
   useEffect(() => {
      if (isSpinning) {
         const lineArrays = slotData.lines.map(lineObj =>
            lineObj.line.map(item => {
                const numericPart = parseInt(item.slice(1)); 
                 return isNaN(numericPart) ? null : numericPart; 
            })
        );
      
          const winCombinationSet = new Set(lineArrays.flatMap((lineArrays) => lineArrays));
          setWinCombination(winCombinationSet);
      }
   }, [isSpinning,slotData]);
 
   // summer cf
   useEffect(() => {
      if (win) {
        const cfSum = slotData.lines.reduce((accumulator, cfObj) => accumulator + cfObj.cf, 0);
        setWinResult(totalBet * cfSum);
      } else {
        setWinResult(0);
      }
    }, [win, slotData]);

    // stop slot
  const stopedSpinner = async () => {
   setStopSpin(true);
   setTimeout(() => {
      setStopSpin(false);
    }, 100)

    };


   // spinner and count logic
   useEffect(() => {
      if (fastStartSpin && listCount > 0) {
         setIsSpinning(true);
         setListCount((listCount) => listCount - 1);
         
      }
      return () => setFastStartSpin(false);
   }, [fastStartSpin]);

   useEffect(() => {
      let interval = null;
      if (startStopSpin && listCount >= 1) {
         interval = setInterval(() => {
            setIsSpinning(true);
            setListCount((listCount) => listCount - 1);
        

         }, 4000);
      } else if (startStopSpin && listCount === 0) {
         setStartStopSpin(false);
         setIsSpinning(true);
         
      }

      return () => clearInterval(interval);
   }, [listCount, startStopSpin]);

    // show price and hide price
   useEffect(() => {
      let timer;
  
      if (win && !isSpinning) {
        setShowWinPrice(true);
  
        timer = setTimeout(() => {
          setShowWinPrice(false);
        }, 1000);
      } else {
        setShowWinPrice(false);
  
      }
  
      return () => {
        clearTimeout(timer);
      };
    }, [win, isSpinning]);


   return (
      <div className="slot-content-bg">
       
            <div className="slot-machine-container">
               <div className='content'>
               <div className="slot-machine" onClick={stopedSpinner} >
                  <div className="reels">
                     {reels.map((reel, reelIndex) => (
                        <div key={reelIndex} className="reel">
                           <VerticalLine
                              reel={reel}
                              setReels={setReels}
                              verticalLineIndex={reelIndex + 1}
                              winItems={Array.from(winCombination)}
                              result={outputArray}
                           />
                        </div>
                     ))}
                  </div>
                  {win  && showWinPrice && !isSpinning && <WinPrice win={winResult}/>}
                
               </div>
               </div>
              
                <ButtonCollection stopedSpinner={stopedSpinner} fetchData={fetchData}/>
                  {isSpinning && <SpinningSound  />}
            </div>
      </div>


   );
};

export default memo(SlotMachine, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
