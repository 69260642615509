import { useEffect } from "react";

const useScrolling = (setIsScrolling, elementRef) => {
  const handleScroll = () => {
    if (elementRef.current.scrollTop > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    const listener = () => {
      if (!elementRef.current ) {
        return;
      }
      handleScroll();
    };
    elementRef.current.addEventListener("scroll", listener);
  }, [elementRef, setIsScrolling]);
};

export default useScrolling;
