

 const Hamburger =({hamburger})=>{
    return(
        <>
            <div className={`burger-1 ${hamburger ? "burger1" : ""}`}></div>
            <div className={`burger-2 ${hamburger ? "burger2" : ""}`}></div>
            <div className={`burger-3 ${hamburger ? "burger3" : ""}`}></div>

        </>
    )

 }
  export  default Hamburger
