import React, { useEffect, useState} from "react";
import { useSpin } from "../../context/SpinContext";

const SlotItem = ({ symbol, win = false }) => {
  const [animationPaused, setAnimationPaused] = useState(false);
  const {isSpinning,setWin} =useSpin()

 //show win
  useEffect(()=>{
    if(win && !isSpinning){
    setWin(true)
   }

},[win,isSpinning])


  //reject transform-scale during open hamburger menu
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const isOverflowHidden = document.body.classList.contains('bg-overflowHidden');
          setAnimationPaused(isOverflowHidden);
        }
      }
    });
    observer.observe(document.body, { attributes: true });
    return () => {
      observer.disconnect();
    };
  }, []);


  return (
      <div className={`symbol ${win && !isSpinning && 'winner-column'}`}>
        <div className = "symbol-item" >
          <img
              src={symbol.src}
              alt={symbol.alt}
              className={`
              ${win && !isSpinning ? 'winner-img' : ''} 
              ${animationPaused ? 'stop-animation' : ''}`}
          />
        </div>
      </div>
  );
};

export default SlotItem;
