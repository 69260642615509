import axios from "axios";

const slotDataApi = () => {
  const apiUrl = 'https://slot.future-systems.am/api';

  return axios.get(apiUrl)
    .then((response) => {
      console.log('Response data:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error; // You can choose to re-throw the error or handle it as needed
    });
}

export default slotDataApi;

