import {  NavLink }  from  "react-router-dom"

import { establishConnection } from "../../hooks/establishConnection";
import { useWallet } from "../../context/WalletContext";
import Walet from "../../assets/icons/wallet.svg"
import Logo from "../../assets/images/logo.png"
import Hamburger from "../homePage/hamburger";
import {useEffect, useState} from "react";



const Header = ()=>{
    const {connected,walletAddress,setConnected,setWallet, setWalletAddress} = useWallet();
    const [hamburger, setHamburger] = useState(false);
    

    // conect fhantom walet
    const establishAndLogConnection = async () => {
        try {
            await establishConnection(setWallet, setWalletAddress, setConnected);
            console.log("Connection established successfully.");
        } catch (error) {
            console.error("Error establishing connection:", error);
        }
    };
    
    window.onload = function() {
        establishAndLogConnection();
    };
    const conectPhantomWallet = async () => {
        await establishAndLogConnection();
    };
    


    const toggleHamburger =()=>{
        setHamburger(!hamburger)
    }
    const closeHamburger =()=>{
        setHamburger(false)

    }


     useEffect(()=>{
        if(hamburger){
            document.body.classList.add('bg-overflowHidden');
        } else{
            document.body.classList.remove('bg-overflowHidden');
 
        }

     },[hamburger])

  return (
      <>

      <div className="homePage-bg">
          <div className="content">
              <div className="homepage-cotainer">
                  <NavLink to="/" className="logo-order" >
                      <div className="logo">
                          <img src={Logo} alt="Logo"/>
                      </div>
                  </NavLink>
                  <nav className={`menu ${hamburger ? "show" : ""}`}>
                      <ul >
                          <li><NavLink to="/whitePaper" onClick={closeHamburger}>WhitePaper</NavLink></li>
                          <li><NavLink to="/faq" onClick={closeHamburger}>FAQ</NavLink></li>
                          <li><NavLink to="/contactUs" onClick={closeHamburger}>Contact us</NavLink></li>
                      </ul>
                  </nav>
                  <div className="btn" onClick={conectPhantomWallet}>

                      {
                          connected
                              ?
                              <button className = ""  >
                                  <p className="wallet-adress">{walletAddress.slice(0, 2) + "..." +  walletAddress.slice(-3)}</p>
                              </button>
                              :
                              <button>
                                  <div className="btn-wallet"><img src = {Walet} alt="Walet"/></div>
                                  <span>Your Wallet</span>
                              </button>
                      }

                  </div>

                  <div className={`${hamburger ? "modal" : "modal-desctop"}`} onClick={closeHamburger}></div>
                  <div className="hamburger-menu">
                      <div
                          className="hamburger"
                          onClick={toggleHamburger}>
                          <Hamburger hamburger={hamburger}/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        
      </>
    )
}

 export default Header
