import {  useRef, useState } from "react";

import useOnClickOutside from '../../hooks/useOnClickOutSide';
import Arrow from "../../assets/icons/arrow.svg"


 const LineCount =({item,onOptionSelect,title})=>{
  const [optionItem, setOptionItem] = useState(Math.min(...item.option));
  const [isOpen, setIsOpen] = useState(false);
 
  const  elementToWatch= useRef(null) ;
  useOnClickOutside(elementToWatch, ()=>setIsOpen(false))
  
    const handleOptionClick = (value) => {
      setOptionItem(value);
      setIsOpen(false);
      onOptionSelect(value);
    };
  

    return (
        <div  ref={elementToWatch} >
        <div className="line-btn" onClick={()=>setIsOpen(!isOpen)} >
              <p>{title}<span>{optionItem}</span> </p>
              <img src={Arrow} alt="Arrow" className={`arrow ${isOpen ? 'up' : ''}`}/>
        </div>

        {
            isOpen &&(
                <div className="line-counts" >
                       <ul>

                        {
                          item.option.map((item,ind)=>{
                            return(
                              <li key={ind} onClick={()=>{handleOptionClick(item)}}><span>{item}</span></li>
                            )
                          })
                        }
                </ul>
                </div>
             
            )
        }
        
        </div>
    )
 }

  export default LineCount