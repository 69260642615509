import React, { useEffect, useRef, useState } from 'react';

import spinerAudio from '../../assets/audio/spining_slot.mp3';
import { useSpin } from '../../context/SpinContext';

const SpinningSound = () => {
  const {isSpinning} = useSpin()
  const audioRef = useRef(null);
  const [audioReady, setAudioReady] = useState(false);

  useEffect(() => {
    const audio = new Audio(spinerAudio);
    audioRef.current = audio;

    const handleCanPlayThrough = () => {
      setAudioReady(true);
    };

    audio.addEventListener('canplaythrough', handleCanPlayThrough);

    return () => {
      if (audioRef.current) {
        audioRef.current?.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.removeEventListener('canplaythrough', handleCanPlayThrough);
      }
    };
  }, []);

  useEffect(() => {
    if (audioReady) {
      if (isSpinning) {
        audioRef.current?.play().catch(error => {
          console.error('Error playing audio:', error);
        });
        localStorage.setItem('audioPlayed', 'true');
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [isSpinning, audioReady]);

  useEffect(() => {
    const hasAudioPlayed = localStorage.getItem('audioPlayed');
    if (!hasAudioPlayed) {
      audioRef.current?.play().catch(error => {
        console.error('Error playing audio:', error);
      });
      localStorage.setItem('audioPlayed', 'true');
    }
  }, []);

  return <div className="player"></div>;
};

export default SpinningSound;
