import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import Routes from './routes';
import { WalletProvider } from '../src/context/WalletContext';
import {SpinProvider} from "./context/SpinContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <WalletProvider>
   <SpinProvider>
      <RouterProvider router={Routes} />
      <App />
       </SpinProvider>
   </WalletProvider>,
);

reportWebVitals();
