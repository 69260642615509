

import symbol1 from '../assets/images/1.png';
import symbol2 from '../assets/images/2.png';
import symbol3 from '../assets/images/3.png';
import symbol4 from '../assets/images/4.png';
import symbol5 from '../assets/images/5.png';
import symbol6 from '../assets/images/6.png';
import symbol7 from '../assets/images/7.png';
import symbol8 from '../assets/images/8.png';
import symbol9 from '../assets/images/9.png';




export const symbols = [
    { src: symbol1, alt: 'img' },
    { src: symbol2, alt: 'img' },
    { src: symbol3, alt: 'img' },
    { src: symbol4, alt: 'img' },
    { src: symbol5, alt: 'img' },
    { src: symbol6, alt: 'img' },
    { src: symbol7, alt: 'img' },
    { src: symbol8, alt: 'img' },
    { src: symbol9, alt: 'img' },
  ];

