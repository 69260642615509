import Banner from "../components/homePage/banner";
import Swap from "../components/swap/swap"


const Homepage= ()=>{
  return (
    <>
    <Banner/>
    <Swap/>
  
    </>

  )
}

 export default Homepage
