import React, { createContext, useContext, useEffect, useState } from 'react';
import { dataSlot } from '../mockData/dataSlot';

const SpinContext = createContext();

export const SpinProvider = ({ children }) => {
    const [listCount, setListCount] = useState(() => {
        const storedListCount = localStorage.getItem('listCount');
        return storedListCount ? parseInt(storedListCount) : 0;
    });
    const [startStopSpin, setStartStopSpin] = useState(false);
    const [fastStartSpin, setFastStartSpin] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const [stopSpin,setStopSpin] = useState(false)
    const [totalBet, setTotalBet] = useState(0);
    const [winResult, setWinResult] = useState(0);
    const [showWinPrice, setShowWinPrice] = useState(false);
    const [play,setPlay] = useState(false);
    const [demo,setDemo] = useState(false);
    const [win,setWin]= useState(false);
    const [slotData,setSlotData] = useState( dataSlot)


    useEffect(() => {
        localStorage.setItem('listCount', listCount.toString());
    }, [listCount]);
  

    const contextValue = {
        listCount,
        setListCount,
        startStopSpin,
        setStartStopSpin,
        fastStartSpin,
        setFastStartSpin,
        totalBet,
        setTotalBet,
        winResult,
        setWinResult,
        play,
        setPlay,
        demo,
        setDemo,
        isSpinning,
        setIsSpinning,
        stopSpin,
        setStopSpin,
        win,
        setWin,
        showWinPrice,
        setShowWinPrice,
        slotData,
        setSlotData



    };

    return <SpinContext.Provider value={contextValue}>{children}</SpinContext.Provider>;
};

export const useSpin = () => {
    return useContext(SpinContext);
};
