import React, { useEffect, useState } from 'react';

import spinnerBtnCountDown from '../../assets/images/spinner-btn-countdown.png';
import { clickWalletPhantom } from "../../hooks/clickWalletPhantom";
import { linebetAndCount } from '../../mockData/linebetAndCount';
import spinnerBtn from '../../assets/images/spinner-btn.png';
import TotalBetAndWinResult from './totalbetAndWinResult';
import { useWallet } from '../../context/WalletContext';
import { useSpin } from "../../context/SpinContext";
import SpiningCount from './spinigcount';
import DialogPopup from './dialogPopup';
import LineCount from './linecount';



const ButtonCollection = ({stopedSpinner,fetchData}) => {
  const { listCount, totalBet, setTotalBet, winResult,isSpinning} = useSpin();
  const [lineCount, setLineCount] = useState(3);
  const [linebet, setLineBet] = useState(50);
  const [prevWinResult, setPrevWinResult] = useState(0);

  const { wallet } = useWallet();

 // totalbet
  useEffect(() => {
    setTotalBet(lineCount * linebet);
  }, [listCount, linebet]);

  //spinner function
  const handleSpinnerClick = () => {
    const demoValue = localStorage.getItem('demo');
    const playValue = localStorage.getItem('play');
    const listCount = localStorage.getItem('listCount')

    if (demoValue && demoValue === 'true' && !playValue) {
      fetchData()
    }



    if (playValue && playValue === 'true' && !demoValue) {
      clickWalletPhantom(wallet, totalBet, listCount)
        .then((item) => {
          fetchData()
        })
        .catch((error) => {
          console.log('Error in clickWalletPhantom:', error);
        });
    }
    
  };



// const fetchData =()=>{
//     slotDataApi().then((data) => {
//       setSlotData(data)
//       setStartStopSpin(true);
//       setFastStartSpin(true);
//       console.log('Data from the API:', data);
//   })
//    .catch((error) => {
//     return fetchData()
//   });
//   }

  const combinedClickHandler = () => {
    if (isSpinning === false) {
      handleSpinnerClick();
    }
    if (isSpinning === true) {
      stopedSpinner();
    }
  };

 
  // result be prev Win Result
  useEffect(() => {
    if (!isSpinning) {
      setPrevWinResult(winResult);
    }
  }, [isSpinning, winResult]);


  return (
    <>
      <div className="button-container content">
        <LineCount item={linebetAndCount[0]} onOptionSelect={setLineCount} title="Line count" />
        <LineCount item={linebetAndCount[1]} onOptionSelect={setLineBet} title="Line Bet" />
        <SpiningCount totalBet={totalBet} handleSpinnerClick={handleSpinnerClick}/>
        <div onClick={combinedClickHandler} className="spinner-btn-img">
          <img src={listCount >= 1 ? spinnerBtnCountDown : spinnerBtn} alt="Spinner button" />
          {listCount >= 1 && <div className="countDown" onClick={stopedSpinner}><span>{listCount}</span></div>}
        </div>
        <TotalBetAndWinResult title="Total Bet" result={totalBet} />
        <TotalBetAndWinResult title="Win" result={!isSpinning ? winResult : prevWinResult} />
     
        {/* <WalletIntegration totalBet={totalBet}  /> */}
        <DialogPopup combinedClickHandler={combinedClickHandler}/>
        
      </div>

    

    </>
  );
};

export default ButtonCollection;
