

const WinPrice = ({win})=>{
    return(
            <div className="winPrice-bg">
                <span>{win}</span>
            </div>
    )

}
export  default WinPrice
