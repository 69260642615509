


export const linebetAndCount = [
    {
        option:[3,5,10,15,20,25,50],
    },
    {
        option:[50,100,200,500,1000,5000,10000],
    },
 
]