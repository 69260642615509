import Header from "../components/main/header";
import React from "react";
import WhitePaper from "../pages/whitepaper";





export default [
    {
        path:"/whitePaper",
        element: <><Header/><WhitePaper/></>
    },
]
