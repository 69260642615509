import React from 'react';

const SwapInput = ({value,setValue}) => {
    const handleInput = (event) => {
        const numericValue = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = numericValue; 
    };

    const onChangeValue = (event) =>{
        setValue(event.target.value)
    }

    return (
        <>
            <input type="number" value={value} onInput={handleInput} onChange={onChangeValue}  />
        </>
    );
};

export default SwapInput;
