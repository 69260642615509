function transformDataForDisplay(winList, symbols) {
  if (!winList) {
      return {
          outputArray: [],
      };
  }

  const keyOrder = Object.keys(winList);
  let filterSymbols = [];
  const outputArray = keyOrder.reduce((result, key, index) => {
      const rowIndex = index % 5;
      const colIndex = Math.floor(index / 5);
      result[rowIndex] = result[rowIndex] ? result[rowIndex] : [];
      result[rowIndex][colIndex] = winList[key];
      return result;
  }, []);

  outputArray.forEach((row) => {
      row.forEach((item, ind,) => {
          if (item >= 1) {
              row[ind] = symbols[item - 1];
              filterSymbols.push(symbols[item - 1]);
          } else if (item < 1) {
              const filteredSymbols = symbols.filter(symbol => !filterSymbols.includes(symbol));
              row[ind] = filteredSymbols[Math.floor(Math.random() * filteredSymbols.length)];
          } else {
              row[ind] = symbols[Math.floor(Math.random() * symbols.length)];
          }
          symbols.forEach((item) => { row.push(item) });
      });
  });

  return {
      outputArray
  };
}

export default transformDataForDisplay;
