import React from "react";

const AccordionItem = ({
    showDescription,
    ariaExpanded,
    item,
    index,
    onClick,
  }) => (
    <div className="faq-question" key={item.question}>
        <button
          aria-expanded={ariaExpanded}
          aria-controls={`faq${index + 1}_desc`}
          data-qa="faq-question-button"
          className={`faq-question-button  ${showDescription}`}
          onClick={onClick}
        >
          {item.question}
        </button>
        <p
          id={`faq${index + 1}-desc`}
          data-qa="faq-desc"
          className={`faq-desc ${showDescription}`}
        >
          {item.answer}
        </p>   
    </div>
  );
  
export default AccordionItem;