 import {Connection, PublicKey, sendAndConfirmTransaction, SystemProgram, Transaction} from '@solana/web3.js';
 import * as buffer from "buffer";
 import { getWallet } from './phantomWallet';


window.Buffer = buffer.Buffer;

const rpcUrl = 'https://long-morning-waterfall.solana-mainnet.discover.quiknode.pro/518985538e0ba735bd0b04a49dc59beb9bca4177/';
const connection = new Connection(rpcUrl, 'confirmed');

export const clickWalletPhantom = async (wallet,totalBet,count) => {
            try {
                  totalBet =0.00000001
                 let finishPrise
                if(count){
                    finishPrise = count * totalBet
                } else {
                    finishPrise = totalBet

                }

                if (!wallet) {
                    alert('Wallet is not connected.');
                    return;
                }
    
                const toAddress = '64z9TxqxVXYThA5wLWoMJUynGjeqmM1vPP5NBr9WRjRy';
                const lamportsToSend = Math.floor(parseFloat(finishPrise) * Math.pow(10, 9));
                const w = getWallet();
    
    
                const transaction = new Transaction().add(
                    SystemProgram.transfer({
                        fromPubkey:w.publicKey,
                        toPubkey: new PublicKey(toAddress),
                        lamports: lamportsToSend,
                    })
                );
    
                transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
                transaction.feePayer =w.publicKey;

    
                return  w.signAndSendTransaction(transaction,{skipPreflight:false})
    
                wallet.signAndSendTransaction()
                const signature = await sendAndConfirmTransaction(connection,[wallet.publicKey]);
                console.log('Transaction signature:', signature);
    
            } catch (error) {
                console.error('Error transferring SOL:', error);
            }
        };