import { useRef, useState, useCallback, memo } from 'react';

import spinnerCount from '../../assets/images/spinner-count.png';
import useOnClickOutside from '../../hooks/useOnClickOutSide';
import {useSpin} from "../../context/SpinContext";

const countColection = [3, 10, 20, 40, 60, 100, 150];

const SpiningCount = ({handleSpinnerClick }) => {
   const elementToWatch = useRef(null);
   useOnClickOutside(elementToWatch, () => setIsOpen(false));
   const {setListCount} = useSpin();
   const [isOpen, setIsOpen] = useState(false);




   const onClickColectionList = useCallback((count) => {
      handleSpinnerClick(count)
      setListCount(count)
      setIsOpen(false)
   },[])

   return (
      <div className="spinnerCountSection" ref={elementToWatch}>
         <div className="spinnerCount" onClick={() => setIsOpen(!isOpen)}>
            <img src={spinnerCount} alt="Spinner Count" />
         </div>

         {isOpen && (
            <div className="spinigCountBg">
               <ul>
                  {countColection.map((count, ind) => {
                     return (
                        <li key={ind} onClick={() => onClickColectionList(count)}>
                           {count}
                        </li>
                     );
                  })}
               </ul>
            </div>
         )}
      </div>
   );
};
export default memo(SpiningCount)
