import React from "react";
import Header from "../components/main/header";
import FaqPage from "../pages/faqPage";





export default [

    {
        path: "/faq",
        element:<><Header/><FaqPage/></>
    }
]
