import ITT  from "../assets/images/ITT.png"
import SOL from "../assets/images/SOL.png"

export const swapOption = [

        {
            src: ITT,
            title: "ITT",
         
        },
        {
            src: SOL,
            title: "SOL",
         
        },
    {
        src: ITT,
        title: "ITT1",

    },
    {
        src: SOL,
        title: "SOL1",

    },


]



