import { useState } from "react";

const ContactUsTextare = ({comment,setComment,error}) =>{
    const [isFocused, setIsFocused] = useState(false);

const handleChange= (e)=>{
    setComment(e.target.value)

}
const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if(!comment){
        setIsFocused(false)
    }
  };

    return(
        <div className="form-item">
        <label htmlFor="comment"  className={isFocused ? "textarea-label":""}>Comment</label>    
      <textarea
        name="comment"
        required
        className={`form-textarea ${isFocused ? "textarea-border":""}`}
        id="comment"
        value={comment}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      ></textarea>      
        {error &&<small className="validate-error">{error}</small>}
        </div>
        
    )
}
 export default ContactUsTextare